import { ThemeLogo } from 'components/themeLogo';
import { ThemeLogoTypeWithCustomData } from 'sites/happybet/utility/types';
import styles from './FooterPaymentsLogoList.module.scss';

export type FooterPaymentsLogoListProps = {
  paymentLogoList: ThemeLogoTypeWithCustomData[];
  isDarkMode: boolean;
};

export type Logo = {
  id: string;
  src: string;
  srcDarkMode?: string;
  alt?: string;
};

export const FooterPaymentsLogoList = ({ paymentLogoList }: FooterPaymentsLogoListProps) => {
  return (
    <section className={styles.container}>
      <ul className={styles.list}>
        {paymentLogoList?.map((item, index) => {
          const { id, src, srcDarkMode, alt, customData } = item;
          return (
            <li key={id + '_' + index} className={styles.item}>
              <ThemeLogo
                src={src}
                alt={alt}
                width={+`${customData?.width ?? 95}`}
                height={+`${customData?.height ?? 32}`}
                srcDarkMode={srcDarkMode}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
};
