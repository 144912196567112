import { Button } from 'components/button/Button';
import { RichText } from 'components/richText';
import styles from './JumioModalBody.module.scss';
import { useEffect } from 'react';
import { useGetLabelByKey } from 'hooks/useLingUI';
export type JumioModalBodyProps = {
  title?: string;
  buttonLabel: string;
  handleSubmit: () => void; //() => Promise<unknown>;
  labels: {
    title: string;
    description: string;
    infoText: string;
  };
  isLoading: boolean;
};

export const JumioModalBody = ({
  title,
  buttonLabel,
  handleSubmit,
  isLoading,
  labels,
  ...rest
}: JumioModalBodyProps) => {
  const labelContinua = useGetLabelByKey('continua');

  useEffect(() => {}, []);
  return (
    <div className={styles.container}>
      <div className={styles.leftColumn}>
        <div className={styles.documentImage} />
      </div>

      <div className={styles.rightColumn}>
        <h2 className={styles.title}>{labels.title}</h2>
        <RichText>{labels.description}</RichText>
        <p dangerouslySetInnerHTML={{ __html: labels.infoText }} />
        <Button
          className={styles.button}
          visualizationType={'secondary'}
          size={'medium'}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {labelContinua}
        </Button>
      </div>
    </div>
  );
};
