import React, { useState } from 'react';
import { Icon, IconsEnum } from '../../Icons';
import styles from './AccordionItem.module.scss';
export type AccordionItemProps = {
  id: string;
  title: string;
  titleClassName?: string;
  children: React.ReactNode;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  id,
  title,
  titleClassName,
  children,
}: AccordionItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <React.Fragment>
      <h4 className={`${titleClassName && ''}`}>
        <button
          type="button"
          id={`header-${id}`}
          aria-controls={`panel-${id}`}
          className={styles.titleContainer}
          data-state={isExpanded ? 'open' : 'closed'}
          onClick={handleClick}
        >
          <span className={styles.title}>{title}</span>
          <Icon iconId={IconsEnum.CHEWRON_DOWN} aria-hidden="true" className={styles.icon} />
        </button>
      </h4>
      <section
        className={`${styles.panel} ${isExpanded ? styles.open : ''}`}
        id={`panel-${id}`}
        aria-labelledby={`header-${id}`}
        hidden={!isExpanded}
        aria-hidden={!isExpanded}
      >
        {children}
      </section>
    </React.Fragment>
  );
};
