import { useMemo, useState } from 'react';

import { Modal } from 'components/modals/components/modal';
import { ReactPortal } from 'components/reactPortal';
import { useGetJumioUrlQuery } from 'features/api/apiSlice';
import { useBlockScroll } from 'hooks/useBlockScroll';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useGetUrlJumioPoaQuery } from 'sites/happybet/features/api/happybetApiSlice';
import { useGetModalByKey } from 'sites/happybet/hooks/useGetModalByKey';
import styles from './JumioModal.module.scss';
import { JumioModalBody } from './components/jumioModalBody';
export type JumioModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  poaType?: string;
  enableExternalUrl?: boolean;
};

export const JumioModal = ({ isOpen, handleClose, enableExternalUrl, poaType }: JumioModalProps) => {
  useBlockScroll(isOpen);

  const {
    isLoading,
    isError,
    data: walletResponse,
  } = useGetJumioUrlQuery(undefined, {
    skip: !!enableExternalUrl,
  });

  const {
    isLoading: isLoadingPoa,
    isFetching: isFetchingPoa,
    isError: isErrorPoa,
    data: dataPoa,
  } = useGetUrlJumioPoaQuery(poaType!, {
    skip: !enableExternalUrl || !poaType,
  });

  const [jumioUrl, setJumioUrl] = useState<string | undefined>();
  const btnLabel = useGetLabelByKey('entra')!;
  const jumioModal = useGetModalByKey('modal-jumio');

  const labels = useMemo(
    () => ({
      title: jumioModal!.title,
      description: jumioModal!.subtitle,
      infoText: jumioModal!.info,
    }),
    [jumioModal]
  );

  return (
    <ReactPortal wrapperId="jumioLabel">
      <Modal modalType="fullheight" modalCategory="jumio-modal" onClose={() => handleClose()}>
        {jumioUrl ? (
          <iframe
            src={decodeURIComponent(jumioUrl)}
            title="Jumio"
            width={'100%'}
            height={'95%'}
            className={styles.iframe}
          ></iframe>
        ) : (
          <JumioModalBody
            labels={labels}
            isLoading={enableExternalUrl ? isLoadingPoa || isFetchingPoa : isLoading}
            buttonLabel={btnLabel!}
            handleSubmit={() => setJumioUrl(enableExternalUrl ? dataPoa?.redirectUrl : walletResponse?.redirectUrl)}
          />
        )}
      </Modal>
    </ReactPortal>
  );
};
