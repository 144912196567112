import styles from './UserMenu.module.scss';
import { Icon, IconsEnum } from 'components/Icons';
import { PathName, getRouteByKey } from 'utility/routes';
import { isGermanSite, isHappybetSite } from 'utility/constant';
import { setToken } from 'features/auth/authSlice';

import { AccountCard } from 'sites/happybet/lib/api/uniqueAccountCardsResponseApi';
import { Button } from 'components/button';
import { ButtonProgress } from 'components/buttonProgress';
import { DatoCmsImageTheme } from 'lib/datoCms/types';
import { MyAccount } from 'components/header/myAccount';
import { SelectAccordion } from 'components/selectAccordion';
import { TitleBar } from 'components/header/titleBar';
import { apiSlice } from 'features/api/apiSlice';
import { dialogOnlyDeInfoChange } from 'sites/happybet/features/dialogDe/dialogDeSlice';
import { isCustomerCardHappybet } from 'sites/happybet/utility/functions';
import { setAutoEsclusionString } from 'features/dashboard/dashboardSlice';
import { setIsCustomerCard } from 'sites/happybet/features/slices/happybetSlice';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { useAppDispatch } from 'sites/happybet/lib/store';
import { useAutoexclude24HMutation } from 'sites/happybet/features/api/happybetApiSlice';
import { useGetLabelsByKeys } from 'hooks/useLingUI';
import { useGetModalByKey } from 'sites/happybet/hooks/useGetModalByKey';
import { useInternalSession } from 'hooks/useInternalSession';
import { useMemo } from 'react';
import { AuthTokenType } from 'features/auth/types';
import { setUpdateWalletTimestamp } from 'features/dashboard/dashboardSlice';
import { addMinutes } from 'date-fns';
import { signOut } from 'features/auth/authActions';

export type UserMenuProps = {
  showDetail: boolean;
  currentIndex: number;
  handleShowDetailsMenu: Function;
  logoSnaiPi: DatoCmsImageTheme;
  accountCards?: AccountCard[];
  selectedCard?: AccountCard;
  setSelectedCard?: (_: AccountCard) => void;
};

export const UserMenu: React.FC<UserMenuProps> = ({
  showDetail,
  currentIndex,
  // handleShowDetailsMenu,
  // logoSnaiPi,
  accountCards,
  selectedCard,
  setSelectedCard,
}: UserMenuProps) => {
  const dispatch = useAppDispatch();
  const { session } = useInternalSession();
  const myAccountUrl = useMemo(() => getRouteByKey(PathName.myAccountUrl), []);

  const [labelVaiAllaDashboard, labelIlMioConto, labelEsci, labelAutoesclusion] = useGetLabelsByKeys([
    'vai-alla-dashboard',
    'il-mio-conto',
    'esci',
    'autoexclusion-string-24',
  ]);

  const logoutModal = useGetModalByKey('logout');
  // const autoEsclusionModal = useGetModalByKey('autoesclusion-modal');

  const [autoexclude] = useAutoexclude24HMutation();

  const listCards = useMemo(() => {
    if (accountCards && session) {
      return accountCards
        .filter((card) => card.carta !== null)
        .map((card) => {
          return {
            label: card.label ?? '',
            value: card.carta!,
          };
        });
    } else return [];
  }, [accountCards, session]);

  const selectedItem = useMemo(() => {
    if (selectedCard && session) {
      return {
        label: selectedCard.label ?? '',
        value: selectedCard.carta!,
      };
    } else return undefined;
  }, [selectedCard, session]);

  const handleChangeCard = async (item: { label: string; value: string }) => {
    // TODO : PAS : VERIFY
    const card = accountCards?.find((card) => card.carta === item.value);
    if (card && setSelectedCard) {
      setSelectedCard(card);
      const tokenResponse = await fetch(`/api/auth/accessToken`, {
        method: 'POST',
        body: JSON.stringify({
          username: session?.user?.cardNumber,
          password: session?.user?.token,
          cartaAssociata: card.carta,
        }),
      });
      const data = (await tokenResponse.json()) as AuthTokenType;
      await dispatch(setToken(data));
      await dispatch(apiSlice.util.resetApiState());
      await dispatch(setIsCustomerCard(isCustomerCardHappybet(card.codiceCsmf.toString())));
      dispatch(setUpdateWalletTimestamp(addMinutes(new Date(), -6).getTime()));
    }
  };

  const performAutoExclusion = () => {
    autoexclude()
      .then(() => {
        dispatch(signOut());
        dispatch(setAutoEsclusionString(labelAutoesclusion));
        dispatch(setIsOpenLoginModalValue(true));
      })
      .catch(() => {
        console.log('Error during exclusion');
      });
  };

  return showDetail ? (
    <div className={`${styles.detailsMenu} ${showDetail ? styles.show : styles.hide} ${currentIndex}`}>
      <ul className={styles.list}>
        <li className={styles.dark}>
          <SelectAccordion items={listCards} onChange={(item) => handleChangeCard(item)} selectedItem={selectedItem} />
        </li>
        <li className={styles.light}>
          <TitleBar
            title={labelVaiAllaDashboard!.toUpperCase()}
            className={'font12Primary'}
            paddingTop={'paddingTop'}
            goToLink="/dashboard"
            containerClassName={styles.myAccountLink}
          />
        </li>
        <li className={styles.dark}>
          <TitleBar
            title={labelIlMioConto!.toUpperCase()}
            className={'font12'}
            noPaddingBottom={'noPaddingBottom'}
            paddingTop={'paddingTop'}
            goToLink={myAccountUrl}
            containerClassName={styles.myAccountLink}
          />
          <MyAccount
            isDropdown={true}
            saldoAmount={session?.user.saldo ?? '0'}
            bonusGoldAmount={session?.user.bonus_gold ?? '0'}
            containerClassName={styles.myAccountBox}
          />
        </li>

        <div className={styles.divider}></div>

        <li className={styles.dark}>
          <div className={styles.autoEsclusionContainer}>
            <ButtonProgress
              visualizationType={'secondary'}
              size={'medium'}
              fullWidth
              handleActionLongPress={() => performAutoExclusion()}
            >
              24h sperre
            </ButtonProgress>
            <span className={styles.infoAutoesclusion}>
              Für eine 24h Sofortsperre den Button 3 Sekunden gedrückt halten. Bitte beachte, dass ein Eintrag in die
              anbieterübergreifende Sperrdatenbank OASIS erfolgt.
            </span>
          </div>
        </li>

        <div className={styles.dividerMargin}></div>

        <li className={styles.dark}>
          <button
            type="button"
            className={styles.btnExit}
            onClick={() => {
              if (isHappybetSite && isGermanSite) {
                dispatch(
                  dialogOnlyDeInfoChange({
                    isOpen: true,
                    icon: IconsEnum.INFO,
                    title: logoutModal?.title,
                    subtitle: logoutModal?.subtitle,
                    info: logoutModal?.info,
                    buttons: [
                      <Button
                        key={0}
                        size="medium"
                        visualizationType={'tertiary'}
                        onClick={() => {
                          dispatch(signOut());
                        }}
                      >
                        {logoutModal?.buttonsBlock[0].label}
                      </Button>,
                      <Button
                        key={1}
                        size="medium"
                        visualizationType={'primary'}
                        onClick={() => {
                          dispatch(
                            dialogOnlyDeInfoChange({
                              isOpen: false,
                            })
                          );
                        }}
                      >
                        {logoutModal?.buttonsBlock[1].label}
                      </Button>,
                    ],
                  })
                );
              } else {
                dispatch(signOut());
              }
            }}
          >
            <Icon iconId={IconsEnum.EXIT} className={styles.icon} color="var(--color-on-bg-secondary)" />
            {labelEsci}
          </button>
        </li>
      </ul>
    </div>
  ) : null;
};
