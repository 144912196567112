import { AnchorLink } from 'components/anchorLink';
import { IconLoader, IconsEnum } from 'components/Icons';
import { LinkProps } from 'next/link';
import { NavigationItemBlock1 } from '../../header/Header';
import styles from './MenuStickyItem.module.scss';
import { useRouter } from 'next/router';

export type MenuStickyItemProps = LinkProps & {
  id: string;
  label: string;
  icon?: { iconName: string }[];
  navigationItemBlock?: NavigationItemBlock1[];
};

export enum MenuItems {
  SPORT = 'sport',
  LIVE = 'live',
  SLOTS = 'slot',
  PROMO = 'promo',
}

export const MenuStickyItem = ({ id, label, icon, navigationItemBlock }: MenuStickyItemProps) => {
  const route = useRouter();

  const iconMatch = (url: string) => {
    if (url.split('/')[1] === MenuItems.SPORT) {
      return 'DISCIPLINA_01';
    } else if (url.split('/')[1] === MenuItems.LIVE) {
      return 'LIVE_HB';
    } else if (url.split('/')[1] === MenuItems.PROMO) {
      return 'BONUS';
    } else if (url.includes(MenuItems.SLOTS)) {
      return 'SLOTS';
    } else {
      return 'DISCIPLINA_01';
    }
  };

  return (
    <AnchorLink
      key={id}
      href={`${navigationItemBlock![0].href}`}
      className={`${styles.container} ${
        route.asPath.includes(navigationItemBlock![0].href as string) ? styles.active : ''
      }`}
    >
      <span className={styles.iconContainer}>
        <IconLoader iconId={IconsEnum[iconMatch(navigationItemBlock![0].href as string)]} className={styles.icon} />
      </span>

      {label}
    </AnchorLink>
  );
};
