import React, { useMemo } from 'react';

import Image from 'next/image';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import styles from './SelectWithIcons.module.scss';
import { useRouter } from 'next/router';

type Option = { label: string; value: string; icon?: { url: string } };

type OptionFormatted = { value: string; label: React.ReactElement };

export type SelectWithIconsProps = {
  options: Array<Option>;
  label?: string;
  value?: Option;
  className?: string;
  hasMarginRight?: boolean;
  hasMarginBottom?: boolean;
  hasMarginTop?: boolean;
  hasMarginLeft?: boolean;
  onChange?: (val: string) => void;
};

const Select = dynamic(() => import('react-select'), { ssr: false });

export const SelectWithIcons = ({ value, options, onChange }: SelectWithIconsProps) => {
  const router = useRouter();
  const optionsSelect: OptionFormatted[] = useMemo(
    () =>
      options.map(({ icon, value, label }) => {
        if (!icon) {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return { value: value, label: <React.Fragment>{label}</React.Fragment> };
        }
        const isLangDisabled = process.env.NEXT_PUBLIC_COUNTRY === 'de' && (value === 'en' || value === 'tr');

        return {
          value: value,
          label: (
            <div className={classNames(styles.option, { [styles.disable]: isLangDisabled })}>
              <Image src={icon.url} className={styles.icon} width={16} height={16} alt={label ?? ''} />
              {label}
            </div>
          ),
          isDisabled: isLangDisabled,
        };
      }),
    [options]
  );

  const valueFormatted = useMemo(
    () => (value ? optionsSelect.find((opt) => opt.value === value.value) : undefined),
    [value, optionsSelect]
  );

  const handleOnChange = (opt: OptionFormatted) => {
    onChange && onChange(opt.value);
    router.push(router.asPath, router.asPath, { locale: opt.value });
  };

  return (
    <Select
      value={valueFormatted}
      onChange={handleOnChange}
      options={optionsSelect}
      instanceId={'custom-select'}
      isSearchable={false}
      styles={{
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            border: '0.1rem solid var(--color-divider-primary)',
            backgroundColor: 'var(--color-surface-02)',
          };
        },
        option: (baseStyles, state) => {
          return {
            ...baseStyles,
            backgroundColor: state.isFocused ? 'var(--color-surface-01)' : 'var(--color-surface-02)',
            color: 'var(--color-on-bg-primary)',
          };
        },
        indicatorSeparator: (baseStyles, state) => {
          return {
            ...baseStyles,
            display: 'none',
          };
        },
        singleValue: (baseStyles, state) => {
          return {
            ...baseStyles,
            color: 'var(--color-on-bg-primary)',
          };
        },
        menu: (baseStyles, state) => {
          return {
            ...baseStyles,
            backgroundColor: 'var(--color-surface-02)',
          };
        },
      }}
    />
  );
};
