import { AnchorLink } from 'components/anchorLink';
import { DatoCmsImageWithCustomData } from 'sites/happybet/utility/types';
import Image from 'next/image';
import styles from './FooterWarning.module.scss';

export type FooterWarningProps = {
  warningLogo: DatoCmsImageWithCustomData;
  warningText: string;
  link?: {
    url: string;
    label: string;
  };
};

export const FooterWarning = ({ warningLogo, warningText, link }: FooterWarningProps) => {
  return (
    <div className={link ? styles.containerWithLink : styles.container}>
      {link && (
        <AnchorLink className={styles.link} href={link.url}>
          {link.label}
        </AnchorLink>
      )}
      <div className={styles.warningTextContainer}>
        <span className={styles.logoContainer}>
          <Image
            src={warningLogo.url}
            alt="Logo Snai"
            width={+`${warningLogo?.customData?.width ?? 128}`}
            height={+`${warningLogo?.customData?.height ?? 32}`}
            className={styles.logo}
          />
        </span>

        <p className={styles.warning}>{warningText}</p>
      </div>
    </div>
  );
};
