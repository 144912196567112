import { ImageProps } from 'next/image';
import { ThemeLogoTypeWithCustomData } from 'sites/happybet/utility/types';
import { DatoCmsImage, DatoCmsImageTheme } from './types';

export const convertInImgType = (image: DatoCmsImage): ImageProps & { customData?: { [key: string]: string } } => {
  const { id, alt, title, url: src, customData } = image;
  return {
    id: id ? id : '',
    alt,
    title,
    src,
    customData,
  };
};

export const convertInThemeLogoType = (logos: DatoCmsImageTheme): ThemeLogoTypeWithCustomData => {
  const { logoLightMode, logoDarkMode, redirectUrlLogo } = logos;
  const { id, alt, title, url: src } = logoLightMode;
  const { url: srcDarkMode } = logoDarkMode;
  return {
    id: id ? id : '',
    alt,
    title,
    src,
    srcDarkMode,
    customData: logoDarkMode.customData!,
    redirectUrlLogo: redirectUrlLogo?.href,
  };
};
