import { Modal } from 'components/modals/components/modal';
import { ReactPortal } from 'components/reactPortal';
import { ThemeLogoType } from 'components/themeLogo';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useAppDispatch } from 'sites/happybet/lib/store';
import { LoginModalCms } from 'sites/happybet/lib/types/dato-cms.response';
import { LoginModalBody } from './components/loginModalBody';

export type LoginModalProps = {
  logoBrand: ThemeLogoType;
  isOpen: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
  loginModal: LoginModalCms;
};

export const LoginModal = ({ logoBrand, isOpen, handleClose, loginModal }: LoginModalProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (router.pathname === '/totocalcio') {
      dispatch(setIsOpenLoginModalValue(false));
    }
  }, [router.pathname]);
  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId="loginModal">
      <Modal label={loginModal.title} logoBrand={logoBrand} modalType="primary" modalCategory="login-modal">
        <LoginModalBody
          title={loginModal.title}
          buttonLabel={loginModal.labelLoginButton}
          handleClose={handleClose}
          placeholderPassword={loginModal.placeholderPassword}
          placeholderPin={loginModal.placeholderPin}
          placeholderUsername={loginModal.placeholderUsernameEmail}
          placeholderCard={loginModal.placeholderCard}
          labelUsername={loginModal.labelUsernameEmail}
          labelPassword={loginModal.labelPassword}
          forgotPasswordLabel={loginModal.labelForgotPassword}
          labelLoginUsernamePassword={loginModal.labelInfoUsernamePassword}
          labelLoginCutomerCard={loginModal.labelInfoCard}
          labelCarta={loginModal.labelCard}
          labelPin={loginModal.labelPin}
          labelAccedi={loginModal.labelButtonSwitchTypologyLogin}
          blockedAccountLoginError={loginModal.errorBlockedAccount}
          usernamePasswordError={loginModal.errorUsernamePassword}
          registerLoginModalMessage={loginModal.labelInfoRegistration}
          registerLabel={loginModal.labelButtonRegistration}
          autoEsclusionMoreInfoUrl={loginModal.linkAutoesclusionMoreInfo?.href}
        />
      </Modal>
    </ReactPortal>
  );
};
