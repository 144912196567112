import styles from 'components/footer/Footer.module.scss';
import { FooterNavigation } from 'components/footer/footerNavigation';
import { NavigationBlock } from 'components/footer/footerNavigation/FooterNavigation';
import { SwitchTheme, SwitchThemeType } from 'components/footer/switchTheme';
import { SelectLang } from 'components/selectLang';
import { useTheme } from 'next-themes';
import { memo } from 'react';
import { FooterPaymentsLogoList } from 'sites/happybet/components/footer/footerPaymentsLogoList';
import { FooterWarning } from 'sites/happybet/components/footerWarning';
import { DatoCmsSelectLingua } from 'sites/happybet/lib/datoCms/types';
import {
  DatoCmsImageWithCustomData,
  ImgWithCustomData,
  ThemeLogoTypeWithCustomData,
} from 'sites/happybet/utility/types';
import { Img } from 'utility/types';
import { FooterDisclaimer } from './footerDisclaimer';
import { CertificationBlock } from './footerDisclaimer/FooterDisclaimer';
import { FooterIppica } from './footerIppica';
export type FooterProps = {
  settingsTitle: string;
  settingsLabelTema: string;
  switchTheme: SwitchThemeType;
  footerNavigation: NavigationBlock[];
  paymentLogoList: ThemeLogoTypeWithCustomData[];
  ippicaTitle: string;
  ippicaLogoList: ImgWithCustomData[];
  certificationsBlock: CertificationBlock[];
  disclaimerParagraph: string;
  warningLogo: DatoCmsImageWithCustomData;
  warningText: string;
  selectLingua: DatoCmsSelectLingua[];
};

export type disclaimerLogoSection = {
  id: string;
  title: string;
  logoList: Img[];
};

export const Footer = memo(function Footer({
  settingsTitle,
  settingsLabelTema,
  switchTheme,
  footerNavigation,
  ippicaTitle,
  ippicaLogoList,
  paymentLogoList,
  disclaimerParagraph,
  warningLogo,
  warningText,
  certificationsBlock,
  selectLingua,
}: FooterProps) {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.leftTopContainer} />
          <div className={styles.leftBottomContainer} />
          <section className={styles.settingsContainer}>
            <h4 className={styles.titleSection}>{settingsTitle}</h4>
            <h5 className={styles.subTitleSection}>{settingsLabelTema}</h5>
            <SwitchTheme labelLight={switchTheme.labelLight} labelDark={switchTheme.labelDark} />
            <h5 className={styles.subTitleSection}>Sprache</h5>
            <SelectLang langOptions={selectLingua} />
          </section>
          <FooterNavigation navigation={footerNavigation} />
          <FooterIppica ippicaTitle={ippicaTitle} ippicaLogoList={ippicaLogoList} />
          <FooterPaymentsLogoList paymentLogoList={paymentLogoList} isDarkMode={isDarkMode} />
          <div className={styles.rightTopContainer} />
          <div className={styles.rightBottomContainer} />
          <FooterWarning warningLogo={warningLogo} warningText={warningText} />
        </div>
      </div>
      <FooterDisclaimer disclaimerParagraph={disclaimerParagraph} certificationsBlock={certificationsBlock} />
    </footer>
  );
});
