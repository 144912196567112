import React, { useCallback, useEffect, useMemo } from 'react';
import {
  DialogDeState,
  dialogOnlyDeInfoChange,
  modalDepositsLast30DaysChange,
  setModalsToOpen,
} from 'sites/happybet/features/dialogDe/dialogDeSlice';
import { RootState, useAppDispatch } from 'sites/happybet/lib/store';
import { PathName, getRouteByKey } from 'utility/routes';

import { IconsEnum } from 'components/Icons';
import { Button } from 'components/button';
import { useLazyGetCachedProfileQuery } from 'features/api/userSlice';
import { useInternalSession } from 'hooks/useInternalSession';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useChangeAreaMutation } from 'sites/happybet/features/api/happybetApiSlice';
import { setJumioModalAfterRegistrationOpen } from 'sites/happybet/features/slices/happybetSlice';
import { useGetModalByKey } from 'sites/happybet/hooks/useGetModalByKey';
import { UsersGetProfileResponse } from 'types/swagger';
import { DialogDe } from '../dialogDe';
import { ModalBalanceLast30Days } from '../modalBalanceLast30Days';

export type ModalsWelcomeProps = {
  newCardDigitalCustomerCardSkip: boolean;
};

export type PostLoginModalType = {
  type:
    | 'balance-last-30-days'
    | 'first-refill'
    | 'create-digital-card'
    | 'limits'
    | 'welcome-jumio-modal'
    | 'welcome-message';
  message?: string;
};

export const ModalsWelcome = ({ newCardDigitalCustomerCardSkip }: ModalsWelcomeProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const { refillUrl, limitsUrl } = useMemo(() => {
    const refillUrl = getRouteByKey(PathName.refillUrl);
    const limitsUrl = getRouteByKey(PathName.myLimitsUrl);

    return { refillUrl, limitsUrl };
  }, []);

  const labelClose = useGetLabelByKey('chiudi');
  const fiveMinutesWaitingPeriodTitle = useGetLabelByKey('5-minutes-waiting-period');
  const modalWelcomeSport = useGetModalByKey('welcome-sport');
  const modalFirstRefill = useGetModalByKey('first-refill');
  const modalLimits = useGetModalByKey('limits');
  const uniqueAccountModal = useGetModalByKey('unique-account-modal');
  const welcomeJumioModal = useGetModalByKey('12-month-account-re-verification');
  const { modalDepositsLast30Days, modalsToOpen } = useSelector((store: RootState) => store.dialogDe as DialogDeState);
  const { dialogOnlyDeInfo } = useSelector((store: RootState) => store.dialogDe as DialogDeState);
  const [getSessionCacheProfile] = useLazyGetCachedProfileQuery();
  const [changeArea] = useChangeAreaMutation();
  const { session, isAuthenticated } = useInternalSession();

  const getModalToOpen = useCallback(
    async (modalToOpen: PostLoginModalType) => {
      let userData: UsersGetProfileResponse | undefined;
      if (session) {
        const { data } = await getSessionCacheProfile();
        userData = data;
      }
      switch (modalToOpen.type) {
        case 'first-refill':
          dispatch(
            dialogOnlyDeInfoChange({
              isOpen: true,
              icon: IconsEnum.PIG,
              title: modalFirstRefill?.title,
              subtitle: modalFirstRefill?.subtitle,
              closeAction: () => {
                dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                if (modalsToOpen.length) {
                  const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                  dispatch(setModalsToOpen(newArrayModalsToOpen));
                }
              },
              buttons: [
                <Button
                  key={0}
                  visualizationType={'primary'}
                  onClick={() => {
                    dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                    router.push(refillUrl);
                    if (modalsToOpen.length) {
                      const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                      dispatch(setModalsToOpen(newArrayModalsToOpen));
                    }
                  }}
                >
                  {modalFirstRefill?.buttonsBlock[0].label}
                </Button>,
              ],
            })
          );
          break;
        case 'limits':
          dispatch(
            dialogOnlyDeInfoChange({
              isOpen: true,
              icon: IconsEnum.FILTER_VERTICAL,
              title: modalLimits?.title,
              subtitle: modalToOpen.message,
              closeAction: () => {
                dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                if (modalsToOpen.length) {
                  const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                  dispatch(setModalsToOpen(newArrayModalsToOpen));
                }
              },
              buttons: [
                <Button
                  key={0}
                  visualizationType={'primary'}
                  onClick={() => {
                    dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                    router.push(limitsUrl);
                    if (modalsToOpen.length) {
                      const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                      dispatch(setModalsToOpen(newArrayModalsToOpen));
                    }
                  }}
                >
                  {modalLimits?.buttonsBlock[0].label}
                </Button>,
              ],
            })
          );
          break;
        case 'welcome-message':
          const isMessageF1022 = userData && userData.welcome_message_msg?.includes('F1022');
          const title = isMessageF1022 ? fiveMinutesWaitingPeriodTitle : undefined;
          const subtitle = isMessageF1022 ? userData?.welcome_message_msg : userData?.welcome_message_msg;
          dispatch(
            dialogOnlyDeInfoChange({
              isOpen: true,
              icon: IconsEnum.INFO,
              title: title,
              subtitle: subtitle,
              closeAction: () => {
                dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                if (modalsToOpen.length) {
                  const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                  dispatch(setModalsToOpen(newArrayModalsToOpen));
                }
              },
              buttons: [
                <Button
                  key={0}
                  visualizationType={'tertiary'}
                  onClick={() => {
                    dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                    if (modalsToOpen.length) {
                      const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                      dispatch(setModalsToOpen(newArrayModalsToOpen));
                    }
                  }}
                >
                  {labelClose}
                </Button>,
              ],
            })
          );
          break;
        case 'balance-last-30-days':
          dispatch(modalDepositsLast30DaysChange(true));
          break;
        case 'create-digital-card':
          dispatch(
            dialogOnlyDeInfoChange({
              isOpen: true,
              icon: IconsEnum.INFO,
              title: uniqueAccountModal?.title,
              subtitle: modalToOpen.message,
              closeAction: () => {
                dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                if (modalsToOpen.length) {
                  const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                  dispatch(setModalsToOpen(newArrayModalsToOpen));
                }
              },
              type: newCardDigitalCustomerCardSkip ? 'unique-account' : 'force-new-card',
              buttons: !newCardDigitalCustomerCardSkip
                ? [
                    <Button
                      key={0}
                      visualizationType={'primary'}
                      onClick={() => {
                        dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                        router.push('/register/new-digital-card');
                        if (modalsToOpen.length) {
                          const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                          dispatch(setModalsToOpen(newArrayModalsToOpen));
                        }
                      }}
                    >
                      {uniqueAccountModal?.buttonsBlock[1].label}
                    </Button>,
                  ]
                : [
                    <Button
                      key={0}
                      visualizationType={'tertiary'}
                      onClick={() => {
                        dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                        if (modalsToOpen.length) {
                          const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                          dispatch(setModalsToOpen(newArrayModalsToOpen));
                        }
                      }}
                    >
                      {uniqueAccountModal?.buttonsBlock[0].label}
                    </Button>,
                    <Button
                      key={0}
                      visualizationType={'primary'}
                      onClick={() => {
                        dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                        router.push('/register/new-digital-card');
                        if (modalsToOpen.length) {
                          const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                          dispatch(setModalsToOpen(newArrayModalsToOpen));
                        }
                      }}
                    >
                      {uniqueAccountModal?.buttonsBlock[1].label}
                    </Button>,
                  ],
            })
          );
          break;
        case 'welcome-jumio-modal':
          dispatch(
            dialogOnlyDeInfoChange({
              isOpen: true,
              icon: IconsEnum.INFO,
              title: welcomeJumioModal?.title,
              subtitle: modalToOpen.message,
              closeAction: () => {
                dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                if (modalsToOpen.length) {
                  const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                  dispatch(setModalsToOpen(newArrayModalsToOpen));
                }
              },
              buttons: [
                <Button
                  key={0}
                  visualizationType={'primary'}
                  onClick={() => {
                    dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                    dispatch(setJumioModalAfterRegistrationOpen(true));
                    if (modalsToOpen.length) {
                      dispatch(setModalsToOpen([]));
                    }
                  }}
                >
                  {welcomeJumioModal?.buttonsBlock[0].label}
                </Button>,
                <Button
                  key={0}
                  visualizationType={'tertiary'}
                  onClick={() => {
                    dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                    if (modalsToOpen.length) {
                      const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== modalToOpen.type);
                      dispatch(setModalsToOpen(newArrayModalsToOpen));
                    }
                  }}
                >
                  {welcomeJumioModal?.buttonsBlock[1].label}
                </Button>,
              ],
            })
          );
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      session,
      modalFirstRefill,
      modalLimits,
      refillUrl,
      limitsUrl,
      router,
      dispatch,
      getSessionCacheProfile,
      labelClose,
      fiveMinutesWaitingPeriodTitle,
      uniqueAccountModal,
      newCardDigitalCustomerCardSkip,
      modalsToOpen,
    ]
  );

  useEffect(() => {
    const origin = router.query['origin'];
    if (origin && origin === 'slot') {
      dispatch(
        dialogOnlyDeInfoChange({
          isOpen: true,
          icon: IconsEnum.INFO,
          title: modalWelcomeSport?.title,
          subtitle: modalWelcomeSport?.subtitle,
          closeAction: () => {
            changeArea({ destinationArea: 'sport' });
          },
          buttons: [
            <Button
              key={0}
              visualizationType={'tertiary'}
              onClick={() => {
                dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                changeArea({ destinationArea: 'sport' });
              }}
            >
              {modalWelcomeSport?.buttonsBlock[0].label}
            </Button>,
          ],
        })
      );
    }
  }, [modalWelcomeSport, router.query, dispatch, changeArea]);

  useEffect(() => {
    if (isAuthenticated && modalsToOpen.length) {
      getModalToOpen(modalsToOpen[modalsToOpen.length - 1]);
    }
  }, [getModalToOpen, isAuthenticated, modalsToOpen, dispatch]);

  return (
    <React.Fragment>
      {modalDepositsLast30Days && <ModalBalanceLast30Days isOpen={modalDepositsLast30Days} />}
      {dialogOnlyDeInfo?.isOpen && (
        <DialogDe
          isMobileFullScreen
          dialogInfo={dialogOnlyDeInfo}
          onOpenChange={() => {
            dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
            dialogOnlyDeInfo.closeAction && dialogOnlyDeInfo.closeAction();
          }}
        />
      )}
    </React.Fragment>
  );
};
