import styles from './Header.module.scss';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { AnchorLink, AnchorLinkType } from 'components/anchorLink';
import { PathName, getRouteByKey } from 'utility/routes';
import { ScrollDirection, useScrollDirection } from 'hooks/useScrollDirection';
import { enComparer, useScreenWidth } from 'hooks/useScreenWidth';
import { useAppDispatch, useTypedSelector } from 'sites/happybet/lib/store';
import { DatoCmsImageTheme } from 'lib/datoCms/types';
import { HeaderNavigation } from './headerNavigation';
import Script from 'next/script';
import { ThemeLogo } from 'components/themeLogo';
import type { ThemeLogoType } from 'components/themeLogo';
import { UserNavigation } from './userNavigation/UserNavigation';
import { breakpoint, isAustrianSite } from 'utility/constant';
import classNames from 'classnames';
import { isTruthy } from 'utility/functions';
import { selectIsCustomerCard } from 'sites/happybet/features/slices/selectors';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { useGetLabelsByKeys } from 'hooks/useLingUI';
import useOutsideAlerter from 'hooks/useClickOutside';
import { useRouter } from 'next/router';
import { useInternalSession } from 'hooks';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';

export type HeaderProps = {
  onlyLogo?: boolean;
  promoHref: string;
  logoBrand: ThemeLogoType;
  logoSnaiPi: DatoCmsImageTheme;
  profileHref: string;
  navigationList: (Navigation | AnchorLinkType)[];
  snaiMessageHref: string;
  userNavigationList: Navigation[];
  mobileUserNavigationList: Navigation[];
};

export type NavigationItemBlock1 = DetailMenu & AnchorLinkType;

export type Navigation = {
  id: string;
  label: string;
  icon?: { iconName: string }[];
  navigationItemBlock?: NavigationItemBlock1[];
};
export type DetailMenu = {
  id: string;
  menuItemBlock?: MenuItemBlock[];
};
export type MenuItemBlock = {
  linkBlock: AnchorLinkType[];
  label: string;
  id: string;
};

export const Header: React.FC<HeaderProps> = ({
  onlyLogo,
  promoHref,
  logoBrand,
  logoSnaiPi,
  profileHref,
  navigationList,
  snaiMessageHref,
  userNavigationList,
  mobileUserNavigationList,
}: HeaderProps) => {
  const dispatch = useAppDispatch();

  const [id, setId] = useState<number | null>(null);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [indexItem, setIndexItem] = useState<number>(0);
  const headerRef = useRef<HTMLElement>(null);
  const isMobile = useScreenWidth(enComparer.less, breakpoint.md);
  const isCustomerCard = useTypedSelector(selectIsCustomerCard);

  const [labelAccedi, labelRegistrati, labelRicarica] = useGetLabelsByKeys([
    'login',
    'login-modal-register-label',
    'ricarica',
  ]);

  const { status } = useInternalSession();
  const isAuthenticated = useIsAuthenticated();
  const allNavigationList = [...navigationList, ...userNavigationList];

  const { push, isReady } = useRouter();

  const { refillUrl, registrationUrl } = useMemo(() => {
    const refillUrl = getRouteByKey(PathName.refillUrl);
    const registrationUrl = getRouteByKey(PathName.registrationUrl);

    return { refillUrl, registrationUrl };
  }, []);

  const { scrollDir, isTop } = useScrollDirection();

  const handleShowDetailsMenu = useCallback(
    // (itemId: string | null, array: Navigation[]) => {
    (itemId: number, array: Navigation[]) => {
      const searchObject = array.find((item) => +item.id === +itemId);
      searchObject && setIndexItem(+searchObject?.id);
      setId((prevState) => {
        if (prevState && prevState === itemId) {
          setShowDetail(false);
          return null;
        } else {
          setShowDetail(true);
          return itemId;
        }
      });
    },
    [setIndexItem, setId, setShowDetail]
  );

  useOutsideAlerter({
    ref: headerRef,
    onClickOutside: () => {
      setShowDetail(false);
      setId(null);
    },
  });

  const objLogo = useMemo(() => {
    const { alt, title, src, srcDarkMode, redirectUrlLogo } = logoBrand ?? {};

    return (
      <AnchorLink href={redirectUrlLogo ?? '/'} className={styles.logoContainer}>
        {src ? <ThemeLogo alt={alt} src={src} fill title={title} priority srcDarkMode={srcDarkMode} /> : ''}
      </AnchorLink>
    );
  }, [logoBrand]);

  if (isTruthy(onlyLogo)) {
    return (
      <header
        ref={headerRef}
        className={classNames(styles.container, {
          [styles.scrolledUp]: scrollDir === ScrollDirection.UP && !isTop,
          [styles.scrolledDown]: scrollDir === ScrollDirection.DOWN && !isTop,
        })}
      >
        <section className={styles.headerOnlyLogo}>
          <div className={styles.wrapperOnlyLogo}>{objLogo}</div>
        </section>
      </header>
    );
  }

  return (
    <header
      ref={headerRef}
      className={classNames(styles.container, {
        [styles.scrolledUp]: scrollDir === ScrollDirection.UP && !isTop,
        [styles.scrolledDown]: scrollDir === ScrollDirection.DOWN && !isTop,
      })}
    >
      <section className={styles.header}>
        <div className={styles.wrapper}>
          <div className={styles.firstCol}>
            {objLogo}
            {!isMobile && (
              <HeaderNavigation
                id={id}
                indexItem={indexItem}
                showDetail={showDetail}
                navigation={navigationList}
                allNavigationList={allNavigationList}
                handleShowDetailsMenu={handleShowDetailsMenu}
              />
            )}
          </div>

          {!isReady || status === 'loading' ? (
            <div className={styles.buttonsListContainer} />
          ) : (
            <div className={styles.buttonsListContainer}>
              {isAuthenticated ? (
                <>
                  <Script id="emx" src="https://secure.adnxs.com/px?id=1475290&t=1" strategy="lazyOnload" />
                  {!isCustomerCard && !isAustrianSite && (
                    <button
                      type="button"
                      className={styles.btnDeposit}
                      onClick={() => {
                        push(refillUrl);
                      }}
                    >
                      {labelRicarica}
                    </button>
                  )}
                  <UserNavigation
                    logoSnaiPi={logoSnaiPi}
                    allNavigationList={allNavigationList}
                    userNavigationList={userNavigationList}
                    mobileUserNavigationList={mobileUserNavigationList}
                    indexItem={indexItem}
                    promoHref={promoHref}
                    showDetail={showDetail}
                    handleShowDetailsMenu={handleShowDetailsMenu}
                    headerHeight={headerRef?.current?.getBoundingClientRect().height}
                  />
                </>
              ) : (
                <React.Fragment>
                  {!isAustrianSite && (
                    <button type="button" className={styles.btnSignUp} onClick={() => push(registrationUrl)}>
                      {labelRegistrati}
                    </button>
                  )}
                  <button
                    type="button"
                    className={styles.btnLogin}
                    suppressHydrationWarning={true}
                    onClick={() => dispatch(setIsOpenLoginModalValue(true))}
                  >
                    {labelAccedi}
                  </button>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </section>
    </header>
  );
};
