import { Button } from 'components/button';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { modalDepositsLast30DaysChange, setModalsToOpen } from 'sites/happybet/features/dialogDe/dialogDeSlice';
import { useGetLabelModalByKey, useGetModalByKey } from 'sites/happybet/hooks/useGetModalByKey';
import { RootState, useAppDispatch } from 'sites/happybet/lib/store';
import { getAmount } from 'utility/functions';
import { DialogDe } from '../dialogDe';
import styles from './ModalBalanceLast30Days.module.scss';

export type ModalBalanceLast30DaysProps = {
  isOpen: boolean;
};

export const ModalBalanceLast30Days = ({ isOpen }: ModalBalanceLast30DaysProps) => {
  const dispatch = useAppDispatch();
  const modalBalance = useGetModalByKey('post-login-balance');
  const { userActivity } = useSelector((root: RootState) => root.happybet);
  const { modalsToOpen } = useSelector((store: RootState) => store.dialogDe);
  const labelStake = useGetLabelModalByKey('post-login-balance-stake-gross', modalBalance);
  const labelLosses = useGetLabelModalByKey('post-login-balance-losses', modalBalance);
  const labelWinnings = useGetLabelModalByKey('post-login-balance-winnings', modalBalance);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
      }),
    []
  );

  return (
    <DialogDe
      isMobileFullScreen
      dialogInfo={{
        isOpen: isOpen,
        icon: 'question',
        title: modalBalance?.title,
      }}
      onOpenChange={() => {
        dispatch(modalDepositsLast30DaysChange(false));
        if (modalsToOpen.length) {
          const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== 'balance-last-30-days');
          dispatch(setModalsToOpen(newArrayModalsToOpen));
        }
      }}
    >
      <div className={styles.contentDialog}>
        <div className={styles.amountDialog}>
          <p className={styles.title}>{labelStake}</p>
          <p className={styles.value}>{formatter.format(getAmount(userActivity?.stakes))}</p>
        </div>
        <div className={styles.amountDialog}>
          <p className={styles.title}>{labelLosses}</p>
          <p className={styles.value}>{formatter.format(getAmount(userActivity?.losses))}</p>
        </div>
        <div className={styles.amountDialog}>
          <p className={styles.title}>{labelWinnings}</p>
          <p className={styles.value}>{formatter.format(getAmount(userActivity?.winnings_paied))}</p>
        </div>
      </div>
      <div
        className={styles.info}
        dangerouslySetInnerHTML={{
          __html: modalBalance!.info,
        }}
      ></div>
      <div className={styles.buttonsDialog}>
        <Button
          size="medium"
          visualizationType="secondary"
          onClick={() => {
            dispatch(modalDepositsLast30DaysChange(false));
            if (modalsToOpen.length) {
              const newArrayModalsToOpen = modalsToOpen.filter((item) => item.type !== 'balance-last-30-days');
              dispatch(setModalsToOpen(newArrayModalsToOpen));
            }
          }}
        >
          {modalBalance?.buttonsBlock[0].label}
        </Button>
      </div>
    </DialogDe>
  );
};
