import Image from 'next/image';
import { ImgWithCustomData } from 'sites/happybet/utility/types';
import styles from './FooterIppica.module.scss';

export type FooterIppicaProps = {
  ippicaTitle: string;
  ippicaLogoList: ImgWithCustomData[];
};

export const FooterIppica: React.FC<FooterIppicaProps> = ({ ippicaTitle, ippicaLogoList }: FooterIppicaProps) => {
  return (
    <section className={styles.container}>
      <h4 className={styles.title}>{ippicaTitle}</h4>
      <div className={styles.logosContainer}>
        {ippicaLogoList?.map((logo) => {
          return (
            <span key={logo.id} className={styles.imageContainer}>
              <Image
                alt=""
                src={logo.src}
                width={+`${logo?.customData?.width ?? 40}`}
                height={+`${logo?.customData?.height ?? 40}`}
                className={styles.image}
              />
            </span>
          );
        })}
      </div>
    </section>
  );
};
