import { SelectWithIcons } from 'components/selectWithIcons';
import { apiSlice } from 'features/api/apiSlice';
import { getLngFromCultureName, useTranslation } from 'hooks/useLingUI';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { DatoCmsSelectLingua } from 'sites/happybet/lib/datoCms/types';
import { defaultLang, langs } from 'utility/constant';

export type SelectLangProps = {
  langOptions: DatoCmsSelectLingua[];
};

export const SelectLang = ({ langOptions }) => {
  const dispatch = useAppDispatch();
  const { lng } = useTranslation();
  const { asPath, locale, push } = useRouter();

  const onLangChangeHandler = useCallback(
    async (nextValue: string) => {
      if (nextValue !== locale) {
        await push(asPath, asPath, { locale: nextValue });
        dispatch(apiSlice.util.resetApiState());
      }
    },
    [locale, asPath, push, dispatch]
  );

  const { selectedValue, selectedOption } = useMemo(() => {
    let selectedValue = locale ?? '';

    if (!langs.includes(selectedValue)) {
      selectedValue = defaultLang;
    }
    let selectedOption = langOptions.find(({ value }: DatoCmsSelectLingua) => value === selectedValue);

    return { selectedValue, selectedOption };
  }, [langOptions, locale]);

  useEffect(() => {
    const value = getLngFromCultureName(selectedValue);
    if (value !== lng) {
      onLangChangeHandler(selectedValue);
    }
  }, [lng, selectedValue, onLangChangeHandler]);

  return <SelectWithIcons value={selectedOption} options={langOptions} onChange={onLangChangeHandler} />;
};
