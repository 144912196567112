import { ThemeLogo } from 'components/themeLogo';
import { ThemeLogoTypeWithCustomData } from 'sites/happybet/utility/types';
import dynamic from 'next/dynamic';
import { isTruthy } from 'utility/functions';
import styles from './FooterDisclaimer.module.scss';

export type FooterDisclaimerProps = {
  disclaimerParagraph: string;
  certificationsBlock: CertificationBlock[];
};
export type CertificationBlock = {
  id: string;
  title: string;
  logoList: ThemeLogoTypeWithCustomData[];
};
// declare global {
//   interface Window {
//     acsbJS: any;
//   }
// }

type LogoSectionItemProps = {
  title: string;
  logoList?: ThemeLogoTypeWithCustomData[];
};

const LogoSectionItem = ({ title, logoList }: LogoSectionItemProps) => {
  const fallbackW = (logo) => {
    isTruthy(logoList?.length) || logo?.alt === 'Partner - World Series Poker' ? 33 : 97;
  };

  return (
    <li className={styles.logoSectionItem}>
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.logosContainer}>
        {logoList?.map((logo: ThemeLogoTypeWithCustomData, index: number) => {
          return (
            <ThemeLogo
              key={logo.alt + '_' + index}
              src={logo.src}
              alt={logo.alt}
              srcDarkMode={logo.srcDarkMode}
              width={+`${logo.customData?.width ?? fallbackW(logo)}`}
              height={+`${logo.customData?.height ?? 33}`}
            />
          );
        })}
      </div>
    </li>
  );
};

export const DynamicUserId = dynamic(() => import('components/userId'), { ssr: false });

export const FooterDisclaimer = ({ certificationsBlock, disclaimerParagraph }: FooterDisclaimerProps) => {
  return (
    <section className={styles.container}>
      {/* <ul className={styles.logoSectionList}>
        {certificationsBlock?.map((item: CertificationBlock) => {
          return <LogoSectionItem key={item.id} title={item.title} logoList={item.logoList} />;
        })}
      </ul> */}
      <div className={styles.disclaimer}>
        <p className={styles.paragraph}>{disclaimerParagraph}</p>
        <p className={styles.paragraph} suppressHydrationWarning>
          {`Frontend Version: ${
            process.env.NEXT_PUBLIC_FRONTEND_VERSION ? process.env.NEXT_PUBLIC_FRONTEND_VERSION : ''
          }`}
        </p>
        {process.env.NEXT_PUBLIC_DEBUG && process.env.NEXT_PUBLIC_DEBUG === 'true' ? (
          <div>
            <p className={styles.paragraph}>
              user id: <DynamicUserId />
            </p>
          </div>
        ) : null}
      </div>
    </section>
  );
};
