import { Icon, IconsEnum } from 'components/Icons';
import { AnchorLinkType } from 'components/anchorLink';
import { selectNumEsitiEveryTicket } from 'features/carrello/carrelloSelectors';
import { tabSelected, toggleCarrello } from 'features/carrello/carrelloSlice';
import { useGetLabelsByKeys } from 'hooks/useLingUI';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { Navigation } from '../header/Header';
import styles from './MenuSticky.module.scss';
import { MenuStickyItem } from './menuStickyItem';

export type MenuStickyProps = {
  navigationList: (Navigation | AnchorLinkType)[];
};

export const MenuSticky = ({ navigationList }: MenuStickyProps) => {
  const dispatch = useAppDispatch();
  const numEsitiEveryTicket = useTypedSelector((state) => selectNumEsitiEveryTicket(state));
  const [labelBiglietto] = useGetLabelsByKeys(['biglietto']);
  const router = useRouter();

  const handleClick = useCallback(
    (tab: string) => {
      dispatch(toggleCarrello({ isFullCart: true, isOpen: false }));
      setTimeout(() => {
        dispatch(toggleCarrello({ isOpen: true }));
      }, 200);
      dispatch(tabSelected(tab));
    },
    [dispatch]
  );

  return (
    <div className={styles.container}>
      {navigationList.map((item: Navigation & LinkProps, index) => {
        return (
          <React.Fragment key={item.label + '_' + index}>
            <MenuStickyItem {...item} />
          </React.Fragment>
        );
      })}
      {router.pathname !== '/' && (
        <button
          type="button"
          className={`${styles.button} ${numEsitiEveryTicket > 0 ? styles.active : ''}`}
          onClick={() => handleClick('tab1')}
        >
          <span className={styles.iconContainer}>
            <Icon iconId={IconsEnum.TICKET} className={styles.icon} />
          </span>
          {labelBiglietto}
          {numEsitiEveryTicket > 0 ? <span className={styles.number}>{numEsitiEveryTicket}</span> : null}
        </button>
      )}
    </div>
  );
};
