import styles from './UserNavigation.module.scss';
import { useGetMyAvatarQuery } from 'features/api/userSlice';
import { selectAccessToken, selectSelectedCard } from 'features/auth/selectors';
import { AnchorLink, AnchorLinkType } from 'components/anchorLink';
import { NewMessagesIndicator } from 'components/newMessagesIndicator';
import { IconLoader, toIconsEnum } from 'components/Icons';
import React, { useEffect, useMemo } from 'react';
import { RootState, useAppDispatch, useTypedSelector } from 'sites/happybet/lib/store';
import { DatoCmsImageTheme } from 'lib/datoCms/types';
import { PersonalAreaModal } from 'components/personalAreaModal';
import Image from 'next/image';
import { Navigation } from '../Header';
import { UserMenu } from '../userMenu';
import { SELECTED_CARD_COOKIE_NAME, breakpoint } from 'utility/constant';
import { isCustomerCardHappybet } from 'sites/happybet/utility/functions';
import { selectAvatar } from 'features/dashboard/selectors';
import { selectIsActiveAvatars } from 'features/configuration/selectors';
import { setAvatar } from 'features/dashboard/dashboardSlice';
import { setSelectedCard } from 'features/auth/authSlice';
import { useBlockScroll } from 'hooks/useBlockScroll';
import { useGetUniqueAccountCardsQuery } from 'sites/happybet/features/api/apiMasterTokenHappybet';
import { useInternalSession } from 'hooks/useInternalSession';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useWindowSize from 'hooks/useWindowSize';
import Persist, { StorageKind } from 'lib/persist';

export type UserNavigationProps = {
  id?: string | null;
  allNavigationList: (Navigation | AnchorLinkType)[];
  userNavigationList: Navigation[];
  mobileUserNavigationList: Navigation[];
  promoHref: string;
  handleShowDetailsMenu: Function;
  showDetail: boolean;
  indexItem: number;
  headerHeight?: number;
  logoSnaiPi: DatoCmsImageTheme;
};

const cookie = Persist(StorageKind.cookie);

export const UserNavigation: React.FC<UserNavigationProps> = ({
  allNavigationList,
  userNavigationList,
  promoHref,
  indexItem,
  showDetail,
  handleShowDetailsMenu,
  mobileUserNavigationList,
  headerHeight,
  logoSnaiPi,
}: UserNavigationProps) => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const { isOpenPersonalAreaModal } = useSelector((store: RootState) => store.modal);
  const router = useRouter();
  const isTablet = width! < breakpoint.lg;
  const isMobile = width! < breakpoint.sm;

  const selectedCard = useTypedSelector(selectSelectedCard);
  const accessToken = useTypedSelector(selectAccessToken);
  const { session } = useInternalSession();
  const avatar = useTypedSelector(selectAvatar);
  const isActiveFeatureAvatars = useTypedSelector(selectIsActiveAvatars);

  const { data: accountCards } = useGetUniqueAccountCardsQuery(undefined, {
    skip: !accessToken,
  });

  const { data: myAvatar } = useGetMyAvatarQuery(undefined, {
    skip: !isActiveFeatureAvatars,
  });

  useEffect(() => {
    if (!avatar) {
      if (myAvatar && myAvatar.length > 0 && isActiveFeatureAvatars) {
        dispatch(setAvatar(myAvatar[myAvatar.length - 1]));
      } else {
        dispatch(setAvatar(undefined));
      }
    }
  }, [dispatch, myAvatar, avatar, isActiveFeatureAvatars]);

  useEffect(() => {
    if (accountCards?.data && accountCards?.data.length > 0 && !selectedCard) {
      const selectedFromCookie = cookie.getItem(SELECTED_CARD_COOKIE_NAME);
      if (selectedFromCookie) {
        dispatch(
          setSelectedCard(accountCards.data.find((card) => card.carta === selectedFromCookie) ?? accountCards.data[0])
        );
      } else {
        dispatch(
          setSelectedCard(
            accountCards.data.find((card) => card.carta === session?.user.cardNumber) ?? accountCards.data[0]
          )
        );
      }
    }
  }, [accountCards, dispatch, selectedCard, session?.user.cardNumber]);

  useBlockScroll(isOpenPersonalAreaModal);

  const navigationItemFavorite = useMemo(
    () => userNavigationList.find((item) => (item.icon ? item.icon[0].iconName === 'heart-outline' : undefined)),
    [userNavigationList]
  );
  const navigationItemMessage = useMemo(
    () => userNavigationList.find((item) => (item.icon ? item.icon[0].iconName === 'message' : undefined)),
    [userNavigationList]
  );

  const lastTwoChar = useMemo(() => (selectedCard?.label ? selectedCard.label.slice(-2) : null), [selectedCard]);

  const usernameCardEllipsed = useMemo(() => {
    const name = selectedCard?.label ?? '';
    if (isMobile) {
      return name && name.length > 9 ? name.slice(0, 7) + '...' + lastTwoChar : name;
    } else return name;
  }, [selectedCard, isMobile, lastTwoChar]);

  return (
    <div className={styles.buttonsListContainer}>
      {userNavigationList &&
        userNavigationList.map((el) => (
          <React.Fragment key={el.id}>
            {
              // el.icon && el.icon.length > 0 && el.icon[0].iconName === 'bonus' ? (
              //   <div className={styles.btnLinkContainer}>
              //     <AnchorLink
              //       href={promoHref}
              //       className={`${styles.btnLink} ${
              //         router.asPath.includes(promoHref) ? styles.isActive : styles.isDisable
              //       }`}
              //     >
              //       <Icon
              //         iconId={toIconsEnum(el.icon![0].iconName)}
              //         className={styles.icon}
              //         color="var(--color-primary)"
              //       />
              //     </AnchorLink>
              //   </div>
              // ) :
              el.icon && el.icon.length > 0 && el.icon[0].iconName !== 'user' ? (
                !isTablet &&
                el.icon[0].iconName !== 'bonus' && (
                  <div className={styles.btnLinkContainer}>
                    <AnchorLink
                      href={el.navigationItemBlock![0].href}
                      className={`${styles.btnLink} ${
                        router.asPath.includes(el.navigationItemBlock![0].href as string)
                          ? styles.isActive
                          : styles.isDisable
                      }`}
                    >
                      <IconLoader
                        iconId={toIconsEnum(el.icon![0].iconName)}
                        className={styles.icon}
                        color="var(--color-on-bg-primary)"
                      />
                    </AnchorLink>
                    {el.icon[0].iconName === 'message' ? <NewMessagesIndicator /> : null}
                  </div>
                )
              ) : (
                <React.Fragment>
                  <div className={styles.btnLinkContainer}>
                    <button
                      aria-expanded={+indexItem === +el.id && showDetail ? 'true' : 'false'}
                      /* className={styles.buttonItem} */
                      className={`${styles.btnLink} ${
                        // (+indexItem === +el.id && showDetail) ||
                        router.asPath.includes('dashboard') &&
                        router.asPath !== navigationItemMessage?.navigationItemBlock![0].href &&
                        router.asPath !== navigationItemFavorite?.navigationItemBlock![0].href
                          ? styles.isActive
                          : styles.isDisable
                      }`}
                      type="button"
                      onClick={() => {
                        handleShowDetailsMenu(+el.id, allNavigationList);
                      }}
                    >
                      {!avatar ? (
                        <IconLoader
                          iconId={toIconsEnum(el.icon![0].iconName)}
                          className={styles.icon}
                          color="var(--color-on-bg-primary)"
                        />
                      ) : (
                        <Image src={avatar.src} alt="avatar" width={24} height={24} style={{ borderRadius: '50%' }} />
                      )}
                      {usernameCardEllipsed && <span className={styles.username}>{usernameCardEllipsed}</span>}
                    </button>
                  </div>
                  {!isTablet ? (
                    <UserMenu
                      logoSnaiPi={logoSnaiPi}
                      showDetail={+indexItem === +el.id && showDetail}
                      currentIndex={indexItem}
                      handleShowDetailsMenu={() => handleShowDetailsMenu(+el.id, allNavigationList)}
                      accountCards={accountCards?.data}
                      selectedCard={selectedCard}
                      setSelectedCard={(e) => dispatch(setSelectedCard(e))}
                    />
                  ) : (
                    <PersonalAreaModal
                      // logoSnaiPi={logoSnaiPi}
                      isOpen={showDetail}
                      headerHeight={headerHeight}
                      accountCards={accountCards?.data}
                      selectedCard={selectedCard}
                      setSelectedCard={(e) => dispatch(setSelectedCard(e))}
                      userNavigationList={mobileUserNavigationList}
                      handleShowDetailsMenu={() => handleShowDetailsMenu(+el.id, allNavigationList)}
                    />
                  )}
                </React.Fragment>
              )
            }
          </React.Fragment>
        ))}
    </div>
  );
};
