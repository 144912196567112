import { AnchorLink, AnchorLinkType } from '../../anchorLink';
import { breakpoint, isHappybetSite, isSnaiSite } from 'utility/constant';
import { enComparer, useScreenWidth } from 'hooks/useScreenWidth';

import { AccordionItem } from '../accordionItem';
import { isClientSide } from 'utility/functions';
import styles from './FooterNavigation.module.scss';
import { useInternalSession } from 'hooks/useInternalSession';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';

declare global {
  interface Window {
    Cookiebot: any;
    CookieConsent: any;
  }
}
export type FooterNavigationProps = {
  navigation: NavigationBlock[];
};

export type NavigationBlock = {
  id: string;
  title: string;
  menuList: MenuItem[];
};
export type MenuItem = {
  id: string;
  label: string;
  links: AnchorLinkType[];
};
export const FooterNavigation = ({ navigation }: FooterNavigationProps) => {
  const idVirtual = '177567838';
  const idSettingCookie = '64024602';
  const idSettingCookieHappybet = '/cookie-edit';

  const { session } = useInternalSession();
  const isMobile = useScreenWidth(enComparer.less, breakpoint.md);
  const isAuthenticated = useIsAuthenticated();

  const onCookiebotDialogHandler = (): void => {
    if (isClientSide() && isSnaiSite) {
      // TODO: check how to remove EventListener for CookiebotOnDialogDisplay
      window.addEventListener('CookiebotOnDialogDisplay', function () {
        document.querySelector('.hinto-cookiebanner-modal') &&
          document.querySelector('.hinto-cookiebanner-modal')!.classList.add('is-visible');
      });

      window.Cookiebot.show();
    } else if (isClientSide() && isHappybetSite) {
      window.CookieConsent.renew();
    }
  };

  return (
    <div className={styles.container}>
      {navigation.map((navigationBlock) => {
        const { id, title, menuList } = navigationBlock;
        if (isMobile) {
          return (
            <AccordionItem key={id} id={id} title={title}>
              <nav className={styles.navigation}>
                <ul className={styles.menu}>
                  {menuList.map((item: any) => {
                    if (
                      item.id === idSettingCookie ||
                      item.label === 'Impostazioni cookie' ||
                      item.links[0].href === idSettingCookieHappybet
                    ) {
                      return (
                        <li key={item.links[0].id} className={styles.menuItem}>
                          <a
                            onClick={onCookiebotDialogHandler}
                            className={`${styles.anchorLink} ${styles.anchorLinkCookie}`}
                          >
                            {item.label}
                          </a>
                        </li>
                      );
                    } else
                      return (
                        <li key={item.id} className={styles.menuItem}>
                          <AnchorLink href={item.links[0].href} className={styles.anchorLink}>
                            {item.label}
                          </AnchorLink>
                        </li>
                      );
                  })}
                </ul>
              </nav>
            </AccordionItem>
          );
        }
        return (
          <section key={id} id={id} className={styles.menuContainer}>
            <h5 className={styles.titleSection}>{title}</h5>
            <nav className={styles.navigation}>
              <ul className={styles.menu}>
                {menuList.map((item: any) => {
                  return item.id === '177214474' && !isAuthenticated ? null : item.id === idVirtual ||
                    item.label === 'Virtual' ? (
                    <li key={item.links[0].id} className={styles.menuItem}>
                      <AnchorLink
                        href={
                          session
                            ? `${process.env.NEXT_PUBLIC_SEAMLESS_BASE_URL}/seamless-login/accesso/${session?.user?.token}/${session?.user?.cardNumber}/newsito/1/`
                            : `${process.env.NEXT_PUBLIC_SEAMLESS_BASE_URL}/virtuali`
                        }
                        className={styles.anchorLink}
                        target="_self"
                      >
                        {item.label}
                      </AnchorLink>
                    </li>
                  ) : item.id === idSettingCookie ||
                    item.label === 'Impostazioni cookie' ||
                    item.links[0].href === idSettingCookieHappybet ? (
                    <li key={item.links[0].id} className={styles.menuItem}>
                      <a
                        onClick={onCookiebotDialogHandler}
                        className={`${styles.anchorLink} ${styles.anchorLinkCookie}`}
                      >
                        {item.label}
                      </a>
                    </li>
                  ) : (
                    <li key={item.links[0].id} className={styles.menuItem}>
                      <AnchorLink href={item.links[0].href} className={styles.anchorLink}>
                        {item.label}
                      </AnchorLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </section>
        );
      })}
    </div>
  );
};
